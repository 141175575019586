import React from "react";
import img1 from "../../assets/images/ser1.png";
import img2 from "../../assets/images/ser2.png";
import img3 from "../../assets/images/ser3.png";
import img4 from "../../assets/images/ser4.png";
const OurServices = () => {
  return (
    <div>
      {/* <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
          Why Choose PS One Way Cabs?        </h4>
        <div className="flex flex-col gap-y-[10px] pt-4">
          <h3 className="font-semibold text-3xl  sm:text-2xl">Convenience:</h3>
          <p>
            At PS One Way Cabs            , we are committed to providing efficient and
            reliable drop taxi services for our customers. We understand the
            importance of punctuality and convenience when it comes to drop taxi
            services, and that is why we strive to make every journey with us a
            hassle-free and comfortable experience.
          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Reliability:</h3>
          <p>
            We understand the importance of punctuality and reliability when it comes to transportation. That's why we have a fleet of well-maintained vehicles and a team of professional drivers committed to getting you to your destination on time, every time.
          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Affordability:</h3>
          <p>
            Quality transportation shouldn't break the bank. At PS One Way Cabs, we offer competitive rates and transparent pricing with no hidden fees. Enjoy the convenience of our drop taxi services without worrying about excessive costs.

          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Safety:</h3>
          <p>
            Your safety is our top priority. All our drivers undergo rigorous background checks and extensive training to ensure they meet the highest safety standards. Additionally, our vehicles are regularly inspected and sanitized to provide you with a clean and secure travel experience.
          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Customer Satisfaction:</h3>
          <p>
            We value your feedback and strive to exceed your expectations with every ride. Our dedicated customer support team is available 24/7 to assist you with any inquiries or special requests, ensuring your journey with PS One Way Cabs is nothing short of exceptional.                        </p>
        </div>



      </div> */}
            <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
            <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">Our Services
            </h4>
            <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
              <h3 className="font-semibold text-2xl">One-Way Drop Services:</h3>
              <p>
              PS One Way Cabs offers one-way drop services, ensuring that passengers can travel from one city to another without the need to pay for a round trip. This service is ideal for customers who only need transportation to a specific destination.
    
              </p>
            </div>
            <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
              <h3 className="font-semibold text-2xl">Airport Transfers:</h3>
              <p>
                Start or end your journey hassle-free with our reliable airport transfer services. We'll ensure you reach your destination on time, relaxed, and ready for your flight.
    
              </p>
            </div>
            <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
              <h3 className="font-semibold text-2xl">Outstation Trips:</h3>
              <p>
                Explore the beauty of Tamil Nadu and beyond with our customizable outstation trip packages. Sit back, relax, and let us take you on an unforgettable journey.
                Book Your Ride Today!
              </p>
            </div>
            <p className="mt-4">
              Experience the ultimate in convenience, reliability, and affordability with PS One Way Cabs. Book your ride today and discover why we're the preferred choice for drop taxi services in Tamil Nadu. Get in touch with us now to plan your next journey!
            </p>
          </div>
    
          <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
        Advantages of Choosing PS One Way Cabs        </h4>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Convenience:</h3>
          <p>
          No need to worry about return trips. Book a one-way cab for a hassle-free travel experience.

          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Cost-Effective:</h3>
          <p>
            Pay only for the distance traveled, making it an economical choice.

          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Flexibility:</h3>
          <p>
Wide range of vehicle options to suit different needs and preferences          </p>
        </div>
                <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Safety:</h3>
          <p>
All drivers are verified, and vehicles are equipped with necessary safety features.          </p>
        </div>
                <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">24/7 Availability:</h3>
          <p>
          Round-the-clock service ensures that you can travel at any time, day or night.          </p>
        </div>

      </div>
      <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
        Why You Choose Us?      </h4>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Wide Range of Destinations:</h3>
          <p>
          Whether traveling to Chennai, Coimbatore, Madurai, Trichy, or any other city within Tamil Nadu, PS One Way Cabs provides comprehensive coverage across the state, connecting various towns and cities efficiently.

          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Comfortable and Well-Maintained Vehicles:</h3>
          <p>
          The fleet includes a variety of vehicles, from compact cars to SUVs, ensuring a comfortable journey for all passengers. Each vehicle is regularly maintained and cleaned, ensuring a pleasant travel experience.
          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Professional and Experienced Drivers:</h3>
          <p>
          PS One Way Cabs prides itself on its team of professional drivers who are not only experienced and skilled but also courteous and knowledgeable about the local routes, ensuring a safe and smooth journey.          </p>
        </div>
                <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Affordable Pricing:</h3>
          <p>
          With transparent pricing and no hidden charges, PS One Way Cabs offers competitive rates for all their services. The focus is on providing value for money, making one-way travel affordable and accessible.
          </p>
        </div>
                <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Easy Booking Process</h3>
          <p>
          The booking process is straightforward and user-friendly. Customers can book a cab through the website, mobile app, or by calling the customer service team. Flexible payment options are available for convenience.          </p>
        </div>

      </div>

      <div className="  px-0 sm:px-4 md:px-8 lg:px-16 bg-[#f5f5f5]  py-3 md:py-5 mt-4 md:mt-6 lg:mt-8">
        <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2  text-center">
          <div className=" flex flex-col gap-y-1">
            <img src={img1} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Easy Online Booking
            </p>
            <p>Pickup and Drop on time</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img2} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Professional Drivers
            </p>
            <p>Well Trained years of experience</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img3} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Variety of Prime Brands
            </p>
            <p>Sweden Innova-Etc</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img4} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Secure Online Payment
            </p>
            <p>24 /7 Customer Support</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
