import React from 'react'

const Look = () => {
    return (
        <div>
        <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
        Customer Support      </h4>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Wide Range of Destinations:</h3>
          <p>
          PS One Way Cabs provides excellent customer support, available 24/7 to assist with any inquiries or issues. The dedicated support team ensures that all customer needs are promptly addressed, enhancing the overall travel experience.
        </p>
        <p>In summary, PS One Way Cabs stands out as a premier drop taxi service in Tamil Nadu, offering unparalleled convenience, affordability, and reliability. Whether for business or leisure, choosing PS One Way Cabs ensures a seamless and enjoyable journey across the beautiful state of Tamil Nadu</p>
        </div>

 </div>
        <div className='container md:py-16 mt-4 px-6 md:px-14 -mb-[100px] relative z-[1]'>
            <div className=' mx-auto bg-[#c80000]  py-4 sm:py-6 md:py-8 lg:py-10 px-4 rounded-md'>
                <div className='flex items-center gap-x-5 gap-y-2 flex-wrap md:flex-nowrap text-white px-4 md:px-8 justify-center md:justify-normal'>
                    <h3 className='text-[30px] sm:text-[34px] md:text-[36px] font-bold'>Still Looking for an Drop Taxi ?</h3>
                    <a href="tel:+91 9790172446" className='th-btn-reverse look-btn'>CALL +91 9790172446 </a>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Look